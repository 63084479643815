<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-2"
      hide-default-footer
      @click:row="selectRow"
      item-key="index"
      :footer-props="{ 'items-per-page-options': [100] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-4"
          @click.stop="showProducts(item)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <ModalProductVenta @search="search" :items="items"/>
  </div>
</template>
<script>
import ModalProductVenta from "../modals/ModalProductVenta";
export default {
  name: "TableProductSales",
  components: {ModalProductVenta},
  props: ['products', 'flag'],
  data () {
    return {
      headers: [
        { text: 'NOMBRE',             value: 'name',              sortable: false, width: "400px", class: "success white--text"},
        { text: 'STOCK',              value: 'stock.stock',       sortable: false, width: "50px", class: "success white--text"},
        { text: 'LABORATORIO',        value: 'laboratory.name',   sortable: false, width: "100px", class: "success white--text"},
        { text: 'UNIDAD MEDIDA',      value: 'presentation.name', sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO UNITARIO',    value: 'sale_unit',         sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO BLISTER',     value: 'sale_blister',      sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO CAJA',        value: 'sale_box',          sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRINCIPIO ACTIVO',   value: 'generic.name',      sortable: false, width: "100px", class: "success white--text"},
        { text: 'ACCIÓN TERAPEÚTICA', value: 'category.name',     sortable: false, width: "100px", class: "success white--text"},
      ],
      purchases: [],
      items: ''
    }
  },
  watch: {
    products () {
      if (this.products.length > 0 && this.flag === true) {
        this.selectRow(this.products[0])
      }
    }
  },
  created () {
    if (this.products.length > 0 && this.flag === true) {
      this.selectRow(this.products[0])
    }
  },
  methods: {
    showProducts (item) {
      this.items = item
    },
    selectRow (row) {
      if (row.stock.stock !== null && row.stock.stock !== 0 && row.stock.stock !== '0F0') {
        const indexItem = this.purchases.findIndex(item => item.id === row.id)
        if(indexItem >= 0){
          this.purchases.filter(value => {
            if (value.box_quantity > 1 && value.id === row.id) {
              let valFirst = 0
              let valLast = 0
              const values = value.quantity.split("F")

              for (let i = 0; i < values.length; i++) {
                valFirst = values[0]
                valLast = values[1]
              }

              if (parseInt(valLast) >= value.box_quantity) {
                valFirst = parseInt(valFirst) + 1
                return value.quantity =  `${valFirst}F0`
              }else {
                valLast = parseInt(valLast) + 1
                return value.quantity =  `${valFirst}F${valLast}`
              }
            }else if (value.id === row.id) {
              return value.quantity = parseInt(value.quantity) + 1
            }
          })
        }else{
          if (row.box_quantity > 1) {
            row.quantity = '0F1'
          }else {
            row.quantity = '1'
          }
          this.purchases.push(row)
        }
        this.$emit('purchases', this.purchases)
      }
    },
    search () {
      this.$emit('searchValue')
    }
  }
}
</script>
