<template>
  <div>
    <v-simple-table  class="elevation-2">
      <template v-slot:default>
        <thead>
          <tr style="background: #4caf50">
            <th class="text-left d-block d-sm-table-cell" style="color: white">
              NRO
            </th>
            <th class="text-left" style="color: white; width: 400px">
              PRODUCTO
            </th>
            <th class="text-left" style="color: white">
              LABORATORIO
            </th>

            <th class="text-left" style="color: white">
              VENCIMIENTO
            </th>
            <th class="text-left" style="color: white">
              CANTIDAD
            </th>
            <th class="text-left" style="color: white">
              PV. UNIDAD
            </th>
            <th class="text-left" style="color: white">
              PV. BLISTER
            </th>
            <th class="text-left" style="color: white">
              PV. CAJA
            </th>
            <th class="text-left" style="color: white">
              TOTAL
            </th>
            <th class="text-left" style="color: white">
              ACCIONES
            </th>
          </tr>
        </thead>
        <tbody>
          <RowSale
            @removeList="removeList($event)"
            @dataRow="dataRow($event)"
            @total="dataTotal"
            :item="item"
            :index="index"
            v-for="(item, index) in purchases"
            :key="index"
          />
        </tbody>
      </template>
    </v-simple-table><br>
    <v-footer color="grey lighten-5">
      <v-row>
        <v-col md="9" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            SUBTOTAL
          </h4>
        </v-col>
        <v-col md="2" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            {{ subtotal | currency}}
          </h4>
        </v-col>
        <v-col md="9" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            TOTAL
          </h4>
        </v-col>
        <v-col md="2" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            {{ total | currency}}
          </h4>
        </v-col>
      </v-row>
    </v-footer>
    <div class="my-4 float-md-right">
      <v-btn
        large
        color="green darken-4"
        dark
        @click="$emit('save')">
        <v-icon left>
          mdi-content-save
        </v-icon>
        REALIZAR VENTA
      </v-btn>
    </div>
    <div class="my-4 float-md-right mr-5">
      <v-btn
        large
        color="lime darken-4"
        dark
        @click.stop="$emit('cancel')"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        CANCELAR
      </v-btn>
    </div>
  </div>
</template>
<script>
import RowSale from "../rows/RowSale";

import {mapState} from "vuex";
export default {
  name: "TableSale",
  components: {RowSale},
  props: ['purchases', 'editedItem', 'resetPurchases'],
  data () {
    return {
      subtotal: 0.0,
      total: 0.0,
      increment: 0.0,
      decrement: 0.0,
      dataPurchase: [],
      totalRow: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    igv: {
      get () {
       return this.editedItem.igv
      },
      set (value) {
        this.editedItem.igv = value
      }
    }
  },
  watch: {
    igv () {
      if (this.editedItem.igv === 1) {
        this.increment = parseFloat(this.total * 0.18).toFixed(2)
        this.total     = (parseFloat(this.total) + parseFloat(this.increment)).toFixed(2)
      }else {
        this.total = (parseFloat(this.total) - parseFloat(this.increment)).toFixed(2)
      }
    },
    resetPurchases () {
      this.reset()
    },
  },
  methods: {
    removeList ($event) {
      this.$emit('remove', $event)

      const index = this.dataPurchase.findIndex(item => item.id === $event)
      this.dataPurchase.splice(index, 1)
      this.dataTotal()
    },
    dataRow ($event) {
      const indexItem = this.dataPurchase.findIndex(item=> item.id === $event.id)
      if(indexItem >= 0){
        return ''
      }else{
        this.dataPurchase.push($event)
        this.$emit('listProducts', this.dataPurchase)
      }
    },
    dataTotal () {
      let quantity = 0.0
      if(this.dataPurchase.length) {
        this.dataPurchase.forEach(item => {
          quantity += parseFloat(item.total)
        })
      }
      this.total               = quantity
      this.subtotal            = quantity
      this.editedItem.subtotal = quantity
      this.editedItem.total    = quantity
    },
    reset () {
      this.subtotal     = 0.0
      this.total        = 0.0
      this.increment    = 0.0
      this.decrement    = 0.0
      this.totalRow     = []
      this.dataPurchase = []
    },
  }
}

</script>
