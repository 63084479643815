<template>
  <tr>
    <td>{{index + 1}}</td>
    <td v-if="item">
      <span>{{item.name}}</span>
    </td>
    <td v-if="item.laboratory">
      <span>{{item.laboratory.name}}</span>
    </td>
    <td v-else>
      <span></span>
    </td>
    <td>
      <span>{{ item.details[0].expiration_date }}</span>
    </td>
    <td v-if="item.box_quantity > 1">
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        v-uppercase
        solo-inverted
        @change="changeQuantity(item.quantity)"
        @input="up"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-else>
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        solo-inverted
        sm="6"
        @input="changeQuantity(item.quantity)"
        type="number"
        min="1"
        step="1"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.sale_unit  }}</span>
      <v-text-field
        v-else
        v-model="item.sale_unit"
        outlined
        dense
        :label=" item.presentation_sale+' x presentacion '"
        @input="saleUnit()"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.blister  }}</span>
      <v-text-field
        v-else
        v-model="item.sale_blister"
        outlined
        dense
        :label="item.blister_quantity+ ' x BLISTER '"
        @input="saleBlister()"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.box }}</span>
      <v-text-field
        v-else
        v-model="item.sale_box"
        outlined
        dense
        :label="item.box_quantity + ' x CAJA ' "
        @input="saleBox()"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item.total === totalPartial">
      <span>{{ totalPartial | currency }}</span>
    </td>
    <td v-else>
      <span>{{ item.total | currency }}</span>
    </td>
    <td>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="toggleEditable()"
      >
        {{ icons.mdiCheckBold }}
      </v-icon>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="removeItem()"
      >
        {{ icons.mdiDelete }}
      </v-icon>
      <template v-else>
        <v-icon
          small
          class="mr-2"
          @click="toggleEditable()"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="removeItem()"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </td>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="success" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Stock insuficiente, stock actual:  {{item.stock.stock}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </tr>
</template>
<script>
import {mdiAccount, mdiCheckBold, mdiDelete, mdiPencil, mdiShareVariant,} from '@mdi/js'
export default {
  name: "RowSale",
  props: ['item', 'index'],
  data() {
    return {
      icons: {
        mdiAccount,
        mdiPencil,
        mdiShareVariant,
        mdiDelete,
        mdiCheckBold
      },
      editable: false,
      menu: false,
      modal: false,
      totalPartial: 0.0,
      dialogDelete: false,
      dialog: false
    }
  },
  computed: {
    total: {
      get () {
        return this.totalPartial
      },
      set (value) {
        this.totalPartial = value
      }
    },
    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.item.quantity = value
      }
    }
  },
  watch: {
    total () {
      this.$emit('total')
    },
    quantity () {
      this.changeQuantity(this.item.quantity)
    }
  },
  created (){
    this.item.lot = ''
    this.item.total = 0.0
    this.item.box = 0.0
    this.item.blister = 0.0
    this.item.unit = 0.0
    this.editable = true
    this.item.product_quantity = 0.0
    this.item.bunit=this.item.sale_unit
    this.changeQuantity(this.item.quantity)
  },
  methods: {
    toggleEditable() {
      this.editable = !this.editable
    },
    changeQuantity (val) {
      let valFirst  = 0
      let valLast   = 0
      let valFirst1 = 0
      let valLast1  = 0
      let valFirst2 = 0
      let valLast2  = 0
      let quotient  = 0
      let rest      = 0
      let productStockt = this.item.stock.stock
      let quantity      = this.item.quantity

      if (val.toString().includes("F")) {
        const valuesproductStockt = productStockt.split("F")
        for (let i = 0; i < valuesproductStockt.length; i++) {
          valFirst1 = valuesproductStockt[0]
          valLast1  = valuesproductStockt[1]
        }

        const valuesquantity = quantity.toString().split("F")
        for (let i = 0; i < valuesquantity.length; i++) {
          valFirst2 = valuesquantity[0]
          valLast2  = valuesquantity[1]
        }

        if (parseInt(valLast2) >= this.item.box_quantity) {
          quantity = (parseInt(valFirst2)+1)+'f'+ (parseInt(valLast2)-parseInt(this.item.box_quantity))
        }
        const values = val.toString().split("F")
        for (let i = 0; i < values.length; i++) {
          valFirst = values[0]
          valLast = values[1]
        }

        quantity = (parseInt(valFirst2) * parseFloat(this.item.box_quantity))+parseInt(valLast2)

        if (quantity) {
          productStockt = parseInt(valFirst1) * parseFloat(this.item.box_quantity)+parseInt(valLast1)
          if(productStockt >= quantity) {
            this.item.box = (parseInt(valFirst) * parseFloat(this.item.sale_box)).toFixed(2)
            if(parseInt(this.item.blister_quantity) === parseInt(this.item.presentation_sale)) {
              this.item.unit = (parseInt(valLast) * parseFloat(this.item.sale_unit)).toFixed(2)
              }else if (parseInt(valLast) >= parseInt(this.item.presentation_sale)) {
              quotient = parseInt(valLast) % parseInt(this.item.blister_quantity)
              rest  = (parseInt(valLast) - parseInt(quotient)) / parseInt(this.item.blister_quantity)
              this.item.blister = (parseInt(rest) * parseFloat(this.item.sale_blister)).toFixed(2)
              this.item.unit    = (parseInt(quotient) * parseFloat(this.item.sale_unit)).toFixed(2)
              }else {
              this.item.blister = (parseFloat(0) * parseFloat(this.item.sale_blister)).toFixed(2)
              this.item.unit    = (parseInt(valLast) * parseFloat(this.item.sale_unit)).toFixed(2)
            }
            this.item.total = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
            this.totalPartial = this.item.total
          }else {
            this.dialog=true
          }
        }
      }else {
        if(productStockt >= quantity){
          this.item.box     = parseFloat(0).toFixed(2)
          this.item.blister = parseFloat(0).toFixed(2)
          this.item.unit    = (parseInt(val) * parseFloat(this.item.sale_unit)).toFixed(2)
          this.item.total   = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
          this.totalPartial = this.item.total
        }else {
          this.dialog = true
        }
      }
      this.$emit('dataRow', this.item)
    },
    saleUnit () {
      if (this.item.sale_unit === "" || this.item.sale_unit === null){
        this.item.sale_unit=parseFloat(this.item.bunit)
        if (parseFloat(this.item.minimum_sale_unit) > parseFloat(this.item.sale_unit)) {
          this.item.sale_unit = parseFloat(this.item.minimum_sale_unit)
          this.changePrice()
        }else {
          this.changePrice()
        }
      }else{
        if (parseFloat(this.item.minimum_sale_unit) > parseFloat(this.item.sale_unit)) {
          this.item.sale_unit = parseFloat(this.item.minimum_sale_unit)
          this.changePrice()
        }else {
          this.changePrice()
        }
      }
    },
    saleBlister () {
      if (parseFloat(this.item.minimum_sale_blister) > parseFloat(this.item.sale_blister)) {
        this.item.sale_blister = parseFloat(this.item.minimum_sale_blister)
        this.changePrice()
      }else {
        this.changePrice()
      }
    },
    saleBox () {
      if (parseFloat(this.item.minimum_sale_box) > parseFloat(this.item.sale_box)) {
        this.item.sale_box = parseFloat(this.item.minimum_sale_box)
        this.changePrice()
      }else {
        this.changePrice()
      }
    },
    removeItem () {
      this.dialogDelete = true
    },
    remove () {
      this.$emit('removeList', this.item.id)
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeDialog () {
      this.dialog = false
      this.quantity=''
    },
    deleteItemConfirm () {
      this.remove()
      this.closeDelete()
    },
    changePrice () {
      if (this.item.quantity.includes("F")) {
        this.changeQuantity(this.item.quantity)
      }else {
        this.item.unit = parseFloat((parseFloat(this.item.quantity) * parseFloat(this.item.sale_unit))).toFixed(2).toString()
        this.item.total   = (parseFloat(this.item.quantity) * parseFloat(this.item.sale_unit))
        this.$emit('dataRow', this.item)
        this.$emit('total')
      }
    },
    up () {
      if (parseInt(this.item.quantity) > -1 && !this.item.quantity.includes('F')) {
        this.$nextTick(() => {
          this.item.quantity =  parseInt(this.item.quantity) + 'F0'
        })
      }
      if (this.item.quantity.includes('F')) {
        let valFirst = 0
        let valLast  = 0
        let resto = 0
        let cocient = 0

        const value=this.item.quantity.split("F")
        for (let i = 0; i < this.item.quantity.length; i++) {
          valFirst = value[0]
          valLast  = value[1]
        }
      
        if (valFirst>0) {
          if(valLast>=this.item.box_quantity) {
            resto = parseInt(valLast)%this.item.box_quantity
            cocient = parseInt(Math.floor(valLast / this.item.box_quantity )) + parseInt(valFirst)
            this.item.quantity =  cocient +'F'+ resto
          }
        }else {
          if(valLast>=this.item.box_quantity) {
            resto = parseInt(valLast)%this.item.box_quantity
            valFirst = Math.floor(valLast / this.item.box_quantity )
            this.item.quantity =  valFirst +'F'+ resto
          }
        }
      }
    }
  }
}
</script>
