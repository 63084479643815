<template>
  <v-card elevation="2" class="mb-3">
    <v-row class="pt-6 mx-auto mb-3">
      <FormNatural
        v-if="editedItem.type_ticket_invoice_id === 2 || editedItem.type_ticket_invoice_id === 3"
        :editedItem="editedItem.person"
        @dni="changeDni"
      />
      <FormLegal
        v-if="editedItem.type_ticket_invoice_id === 1 || editedItem.type_ticket_invoice_id === 4"
        :editedItem="editedItem.person"
        @ruc="changeRuc"
      />
      <v-col md="5" sm="12" xs="12">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            xs="12"
            md="3"
          >
            <v-autocomplete
              v-model="editedItem.type_ticket_invoice_id"
              :items="listInvoices"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Comprobante"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            xs="12"
            md="3"
          >
            <v-autocomplete
              v-model="editedItem.type_buy_id"
              :items="listBuys"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Forma de pago"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="3"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.date"
                  label="Fecha compra"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  outlined
                  dense
                  disabled
                  @blur="date = parseDate(editedItem.date)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu = false"
                color="green lighten-1"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            xs="12"
            md="2"
          >
            <v-text-field
              v-model="editedItem.points"
              outlined
              dense
              label="Puntos"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="2"
          >
            <v-checkbox
              v-model="editedItem.igv"
              label="IGV"
              color="success"
              value="success"
              hide-details
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";
import FormNatural from "./FormNatural";
import FormLegal from "./FormLegal";


export default {
  name: "FormSale",
  components: {FormLegal, FormNatural},
  props: ["editedItem"],
  data () {
    return {
      menu: false,
      listInvoices: [],
      listBuys: [],
      dni: '',
      ruc: '',
      date: new Date().toISOString().substr(0, 10),

    }
  },
  computed: {
    opening: {
      get () {
        return this.opening_date
      },
      set (val) {
        this.opening_date = val
      }
    },
  },
  watch: {
    date () {
      this.editedItem.date = this.formatDate(this.date)
    },
    opening () {
      this.editedItem.date = this.formatDate(this.opening_date)
    },
    dni (search) {
      if (search.length === 8) {
        axios.get(`/api/assignments/filter/person?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    },
    ruc (search) {
      if (search.length === 11) {
        axios.get(`/api/assignments/filter/legal?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    }
  },
  mounted() {
    this.listTypeTicketInvoice()
    this.listTypeBuy()
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    listTypeTicketInvoice () {
      axios.get('/api/ticket/invoices')
        .then(response => this.listInvoices = response.data.typeTicketInvoice)
        .catch(error => console.log(error))
    },
    listTypeBuy () {
      axios.get('/api/ticket/buys')
        .then(response => this.listBuys = response.data.typeBuy)
        .catch(error => console.log(error))
    },
    changeDni (search) {
      this.dni = search
    },
    changeRuc (search) {
      this.ruc = search
    }
  }
}
</script>

<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
