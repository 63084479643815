<template>
    <v-container fluid>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            COMPROBANTE DE VENTA
          </h3>
        </v-card-title>
      </v-card><br>
      <FormSale :editedItem="editedItem"/>
      <v-card elevation="2" class="mb-3">
        <v-col
          class="pt-7 pb-0"
          sm="12"
          xs="12"
          md="3"
        >
          <v-text-field
            v-model="search"
            ref="buscarProducto"
            outlined
            dense
            label="Buscar Producto"
            @keyup="filterProducts(search)"
            @keyup.enter="filterBarcode(search)"
            :append-icon="svgPath"
          ></v-text-field>
        </v-col>
      </v-card>
      <TableProductSales
        v-if="products.length"
        v-show="showTableProduct"
        :products="products"
        :flag="flagBarcode"
        @purchases="purchases($event)"
        @searchValue="searchValue"
        :resetProducts="resetProducts"
      />
      <TableSale
        v-show="showTableSale"
        :purchases="purchase"
        :resetPurchases="resetPurchases"
        :editedItem="editedItem"
        :isRequest="clicked"
        @remove="removeList"
        @listProducts="listProducts($event)"
        @save="saveVoucherSale"
        @cancel="cancelVoucherSale"
      />
      <v-dialog v-model="dialogPrint" max-width="500px">
        <v-card>
          <v-card-title class="headline">{{ text }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="success" text @click=printSale()>Imprimir</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar2"
        >
          Elige productos a vender!!!
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar2 = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
</template>
<script>
import {mdiMagnify} from '@mdi/js';
import TableProductSales from "../../components/tables/TableProductSales";
import axios from "axios";
import TableSale from "../../components/tables/TableSale";
import {Errors} from "../../plugins/errors";
import FormSale from "../../components/forms/FormSale";
import {mapState} from "vuex";

export default {
  name: "VoucherSale",
  components: {FormSale, TableSale, TableProductSales},
  data () {
    return {
      dialogPrint: false,
      clicked: false,
      sale_id: 0,
      svgPath: mdiMagnify,
      products: [],
      purchase: [],
      listPurchases: [],
      selectProducts: [],
      resetProducts: [],
      resetPurchases: [],
      snackbar: false,
      snackbar2: false,
      showTableProduct: false,
      flagBarcode: false,
      showTableSale: true,
      search: '',
      text: this.err,
      updateSearch: '',
      date: new Date().toISOString().substr(0, 10),
      opening_date: new Date().toISOString().substr(0, 10),
      editedItem: {
        person: {
          dni: '',
          firstName: '',
          lastName: '',
          ruc: '',
          phone: '',
          email: '',
          businessName: '',
          durection: '',
          errors: new Errors(),
        },
        supplier: '',
        type_ticket_invoice_id: 3,
        type_buy_id: 1,
        number: '',
        date: this.formatDate(new Date().toISOString().substr(0, 10)),
        igv: 0,
        subtotal: 0.0,
        total: 0.0,
        points: 0
      },
      parentLaboratory: {
        id: null,
        name: ''
      },
      parentGeneric: {
        id: null,
        name: ''
      },
      parentCategory: {
        id: null,
        name: ''
      },
      parentPresentation: {
        id: null,
        name: ''
      },
      parentLocation: {
        id: null,
        name: ''
      },
    }
  },
  computed: {
    ...mapState('box', ['cashes']),
    ...mapState('auth', ['user'])
  },
  watch: {

  },
  mounted() {
    this.listTypeInvoicePurchases()
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    filterProducts (search) {
      if (search.length >= 4) {
        let modifyList = []
        let quotient = 0
        let rest     = 0
        axios.get(`/api/ticket/products/${search}`)
          .then(response => {
            modifyList = response.data.data
            if (modifyList.length > 0) {
              modifyList.filter((value) => {
                return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
              })
              modifyList.filter((value) => {
                return value.generic == null ? value.generic = this.parentGeneric : value.generic
              })
              modifyList.filter((value) => {
                return value.category == null ? value.category = this.parentCategory : value.category
              })
              modifyList.filter((value) => {
                return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
              })
              modifyList.filter((value) => {
                return value.location == null ? value.location = this.parentLocation : value.location
              })

              modifyList.filter((value) => {
                if (value.stock !== null) {
                  if (value.box_quantity > 1) {
                    quotient = parseInt(value.stock.stock) % parseInt(value.box_quantity)
                    rest  = (parseInt(value.stock.stock) - parseInt(quotient)) / parseInt(value.box_quantity)
                    value.stock.stock = rest + 'F' + quotient
                  }else {
                    value.stock.stock = parseInt(value.stock.stock)
                  }
                  return value.stock
                }
              })
              this.products = modifyList
              this.products.length ? this.showTableProduct = true : this.showTableProduct = false
              this.showTableSale = false
            }else {
              this.showTableProduct = false
              this.showTableSale = true
            }
          })
          .catch(error => console.log(error))
      }else {
        this.showTableProduct = false
        this.showTableSale = true
      }
    },
    listTypeInvoicePurchases () {
      axios.get('/api/invoices/type')
        .then(response => this.listPurchases = response.data.listPurchases)
        .catch(error => console.log(error))
    },
    listProducts ($event) {
      const indexItem = this.selectProducts.findIndex(x => x.id === $event.id);
      if(indexItem >= 0){
        return ''
      }else{
        this.selectProducts = $event
      }
    },
    saveVoucherSale () {
      if (this.clicked) {
        return;
      }

      if (!this.purchase.length) {
        this.snackbar2 = true
        return
      }

      this.clicked = true;

      let createVoucherSale = {
        'ruc'                     : this.editedItem.person.ruc,
        'dni'                     : this.editedItem.person.dni,
        'firstName'               : this.editedItem.person.firstName,
        'lastName'                : this.editedItem.person.lastName,
        'businessName'            : this.editedItem.person.businessName,
        'direction'               : this.editedItem.person.direction,
        'phone'                   : this.editedItem.person.phone,
        'email'                   : this.editedItem.person.email,
        'date'                    : this.editedItem.date,
        'subtotal'                : this.editedItem.subtotal,
        'igv'                     : this.editedItem.igv,
        'total'                   : this.editedItem.total,
        'type_ticket_invoice_id'  : this.editedItem.type_ticket_invoice_id,
        'type_buy_id'             : this.editedItem.type_buy_id,
        'points'                  : this.editedItem.points,
        'cash_id'                 : this.getCash(),
        'sales'                   : this.selectProducts,
        'area_assignment_id'      : this.user[0].id,
        'entity_id'               : this.user[0].area.entity.id
      }
      axios.post('/api/ticket', createVoucherSale)
        .then((response) => {
          this.clicked = false;
          this.sale_id = response.data.idticktet
          this.showTableSale = true
          this.dialogPrint = true
          this.text = response.data.message
          this.reset()
        },
            error => this.editedItem.person.errors.record(error.response.data.errors)
        );
    },
    getCash () {
       let cash_id = 0
      this.cashes.map(c => {
        if (c.state === 1 && c.assignment.person_id === this.user[0].person_id) {
          cash_id = c.id
        }
      })

      return cash_id
    },
    purchases ($event) {
      this.purchase = $event
      this.showTableSale = true
      this.showTableProduct  = false
      this.flagBarcode = false
      this.search = ''
    },
    removeList ($event) {
      const index = this.purchase.findIndex(item => item.id === $event)
      this.purchase.splice(index, 1)
    },
    reset () {
      this.selectProducts = []
      this.products       = []
      this.purchase       = []
      this.resetPurchases = []
      this.search         = ''
      this.editedItem = {
        person: {
          dni: '',
          ruc: '',
          phone: '',
          email: '',
          businessName: '',
          durection: '',
          firstName: '',
          lastName: '',
          errors: new Errors(),
        },
        supplier: '',
        type_ticket_invoice_id: 3,
        type_buy_id: 1,
        number: '',
        date: new Date().toISOString().substr(0, 10),
        igv: 0,
        subtotal: 0.0,
        total: 0.0,
        points: 0,
        errors: new Errors()
      }
    },
    cancelVoucherSale () {
      this.reset()
    },
    searchValue () {
      this.filterProducts()
    },
    printSale(){
      let request={
        'entity_id':this.user[0].area.entity.id,
        'sale_id': this.sale_id
      }

      axios.post("api/ticket/invoice/print", request).then(
        response=>{
          this.dialogPrint=false
          console.log(response)
        }
      ).catch(e=>console.log(e))

    },
    closeDelete(){
      this.dialogPrint=false
    },
    filterBarcode (search) {
      let modifyList = []
      let quotient = 0
      let rest     = 0
      axios.get(`/api/ticket/barcode/${search}`)
        .then(response => {
          modifyList = response.data.data
          if (modifyList.length > 0) {
            modifyList.filter((value) => {
              return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
            })
            modifyList.filter((value) => {
              return value.generic == null ? value.generic = this.parentGeneric : value.generic
            })
            modifyList.filter((value) => {
              return value.category == null ? value.category = this.parentCategory : value.category
            })
            modifyList.filter((value) => {
              return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
            })
            modifyList.filter((value) => {
              return value.location == null ? value.location = this.parentLocation : value.location
            })
            modifyList.filter((value) => {
              if (value.stock !== null) {
                if (value.box_quantity > 1) {
                  quotient = parseInt(value.stock.stock) % parseInt(value.box_quantity)
                  rest  = (parseInt(value.stock.stock) - parseInt(quotient)) / parseInt(value.box_quantity)
                  value.stock.stock = rest + 'F' + quotient
                }else {
                  value.stock.stock = parseInt(value.stock.stock)
                }
                return value.stock
              }
            })
            this.products = modifyList
            this.flagBarcode = true
          }else {
            return ''
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style scoped>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
